import { default as indexGp16p0SGc7Meta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20250127090112/pages/index.vue?macro=true";
import { default as loginzh1CZCACOyMeta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20250127090112/pages/login.vue?macro=true";
import { default as _91id_93HhtblJunSTMeta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20250127090112/pages/orders/[id].vue?macro=true";
import { default as createliFpb6ryg5Meta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20250127090112/pages/orders/create.vue?macro=true";
import { default as index98tdHRJcEMMeta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20250127090112/pages/orders/index.vue?macro=true";
import { default as print_45jobsfLVgKamHOKMeta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20250127090112/pages/print-jobs.vue?macro=true";
import { default as indexuidupUlr5RMeta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20250127090112/pages/reservations/index.vue?macro=true";
import { default as lanesYktx9krnW8Meta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20250127090112/pages/reservations/lanes.vue?macro=true";
import { default as settlementkaj5pSfo6rMeta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20250127090112/pages/settlement.vue?macro=true";
import { default as _91merchantId_93xq6bsuJxovMeta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20250127090112/pages/setup/[merchantId].vue?macro=true";
import { default as indexXUy9IJtCNAMeta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20250127090112/pages/setup/index.vue?macro=true";
import { default as editwImiB1E0fpMeta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20250127090112/pages/stock-statuses/[id]/edit.vue?macro=true";
import { default as indexGUxZ6ufRxEMeta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20250127090112/pages/stock-statuses/[id]/index.vue?macro=true";
import { default as createXkoGVuj9VoMeta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20250127090112/pages/stock-statuses/create.vue?macro=true";
import { default as index4T4QrXexaDMeta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20250127090112/pages/stock-statuses/index.vue?macro=true";
import { default as stock_45statuseskohOW4DuhRMeta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20250127090112/pages/stock-statuses.vue?macro=true";
import { default as indexmB8IUGshz5Meta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20250127090112/pages/stock/index.vue?macro=true";
import { default as createyTD1SanL29Meta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20250127090112/pages/stock/statuses/create.vue?macro=true";
import { default as createHgAqXX0UUkMeta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20250127090112/pages/stock/transfers/create.vue?macro=true";
import { default as indexEbuMYQGp6XMeta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20250127090112/pages/tables/[tableId]/orders/[orderId]/index.vue?macro=true";
import { default as splitWzrILA7zTpMeta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20250127090112/pages/tables/[tableId]/orders/[orderId]/split.vue?macro=true";
import { default as _91orderId_9371bmlToTmcMeta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20250127090112/pages/tables/[tableId]/orders/[orderId].vue?macro=true";
import { default as createGiPr9UjmE9Meta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20250127090112/pages/tables/[tableId]/orders/create.vue?macro=true";
import { default as _91tableId_93GxVFTVA9OEMeta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20250127090112/pages/tables/[tableId].vue?macro=true";
import { default as index8q9IWxbRbcMeta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20250127090112/pages/tables/index.vue?macro=true";
import { default as indexucS0OTNoQBMeta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20250127090112/pages/takeaway-orders/[id]/index.vue?macro=true";
import { default as splitCFFLhNAGP1Meta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20250127090112/pages/takeaway-orders/[id]/split.vue?macro=true";
import { default as _91id_93c8lPEqUfOuMeta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20250127090112/pages/takeaway-orders/[id].vue?macro=true";
import { default as create33zIOQvtSdMeta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20250127090112/pages/takeaway-orders/create.vue?macro=true";
import { default as indexwutD65zlsTMeta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20250127090112/pages/takeaway-orders/index.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20250127090112/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: loginzh1CZCACOyMeta || {},
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20250127090112/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "orders-id",
    path: "/orders/:id()",
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20250127090112/pages/orders/[id].vue").then(m => m.default || m)
  },
  {
    name: "orders-create",
    path: "/orders/create",
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20250127090112/pages/orders/create.vue").then(m => m.default || m)
  },
  {
    name: "orders",
    path: "/orders",
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20250127090112/pages/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "print-jobs",
    path: "/print-jobs",
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20250127090112/pages/print-jobs.vue").then(m => m.default || m)
  },
  {
    name: "reservations",
    path: "/reservations",
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20250127090112/pages/reservations/index.vue").then(m => m.default || m)
  },
  {
    name: "reservations-lanes",
    path: "/reservations/lanes",
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20250127090112/pages/reservations/lanes.vue").then(m => m.default || m)
  },
  {
    name: "settlement",
    path: "/settlement",
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20250127090112/pages/settlement.vue").then(m => m.default || m)
  },
  {
    name: "setup-merchantId",
    path: "/setup/:merchantId()",
    meta: _91merchantId_93xq6bsuJxovMeta || {},
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20250127090112/pages/setup/[merchantId].vue").then(m => m.default || m)
  },
  {
    name: "setup",
    path: "/setup",
    meta: indexXUy9IJtCNAMeta || {},
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20250127090112/pages/setup/index.vue").then(m => m.default || m)
  },
  {
    name: stock_45statuseskohOW4DuhRMeta?.name,
    path: "/stock-statuses",
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20250127090112/pages/stock-statuses.vue").then(m => m.default || m),
    children: [
  {
    name: "stock-statuses-id-edit",
    path: ":id()/edit",
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20250127090112/pages/stock-statuses/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "stock-statuses-id",
    path: ":id()",
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20250127090112/pages/stock-statuses/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "stock-statuses-create",
    path: "create",
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20250127090112/pages/stock-statuses/create.vue").then(m => m.default || m)
  },
  {
    name: "stock-statuses",
    path: "",
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20250127090112/pages/stock-statuses/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "stock",
    path: "/stock",
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20250127090112/pages/stock/index.vue").then(m => m.default || m)
  },
  {
    name: "stock-statuses-create",
    path: "/stock/statuses/create",
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20250127090112/pages/stock/statuses/create.vue").then(m => m.default || m)
  },
  {
    name: "stock-transfers-create",
    path: "/stock/transfers/create",
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20250127090112/pages/stock/transfers/create.vue").then(m => m.default || m)
  },
  {
    name: "tables-tableId",
    path: "/tables/:tableId()",
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20250127090112/pages/tables/[tableId].vue").then(m => m.default || m),
    children: [
  {
    name: _91orderId_9371bmlToTmcMeta?.name,
    path: "orders/:orderId()",
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20250127090112/pages/tables/[tableId]/orders/[orderId].vue").then(m => m.default || m),
    children: [
  {
    name: "tables-tableId-orders-orderId",
    path: "",
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20250127090112/pages/tables/[tableId]/orders/[orderId]/index.vue").then(m => m.default || m)
  },
  {
    name: "tables-tableId-orders-orderId-split",
    path: "split",
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20250127090112/pages/tables/[tableId]/orders/[orderId]/split.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "tables-tableId-orders-create",
    path: "orders/create",
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20250127090112/pages/tables/[tableId]/orders/create.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "tables",
    path: "/tables",
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20250127090112/pages/tables/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93c8lPEqUfOuMeta?.name,
    path: "/takeaway-orders/:id()",
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20250127090112/pages/takeaway-orders/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "takeaway-orders-id",
    path: "",
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20250127090112/pages/takeaway-orders/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "takeaway-orders-id-split",
    path: "split",
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20250127090112/pages/takeaway-orders/[id]/split.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "takeaway-orders-create",
    path: "/takeaway-orders/create",
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20250127090112/pages/takeaway-orders/create.vue").then(m => m.default || m)
  },
  {
    name: "takeaway-orders",
    path: "/takeaway-orders",
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20250127090112/pages/takeaway-orders/index.vue").then(m => m.default || m)
  }
]