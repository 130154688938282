import { default as indexmGhQYOOadGMeta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20250124223046/pages/index.vue?macro=true";
import { default as loginDrM4ltbgJvMeta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20250124223046/pages/login.vue?macro=true";
import { default as _91id_930nC70ksg4TMeta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20250124223046/pages/orders/[id].vue?macro=true";
import { default as createnB0obGxNeFMeta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20250124223046/pages/orders/create.vue?macro=true";
import { default as index7KJ97yziYvMeta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20250124223046/pages/orders/index.vue?macro=true";
import { default as print_45jobsoo2ZxGxWUYMeta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20250124223046/pages/print-jobs.vue?macro=true";
import { default as indexod8SXDhcDvMeta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20250124223046/pages/reservations/index.vue?macro=true";
import { default as lanesxnqAJgnquAMeta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20250124223046/pages/reservations/lanes.vue?macro=true";
import { default as settlementNQvljLSRwmMeta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20250124223046/pages/settlement.vue?macro=true";
import { default as _91merchantId_9378YZbPHGq4Meta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20250124223046/pages/setup/[merchantId].vue?macro=true";
import { default as indexaZuXKilMcpMeta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20250124223046/pages/setup/index.vue?macro=true";
import { default as editKZxqz4mRhQMeta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20250124223046/pages/stock-statuses/[id]/edit.vue?macro=true";
import { default as indexvNiyYTBgWeMeta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20250124223046/pages/stock-statuses/[id]/index.vue?macro=true";
import { default as create1M1e4iu4ZCMeta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20250124223046/pages/stock-statuses/create.vue?macro=true";
import { default as indexYXxnvAIAv8Meta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20250124223046/pages/stock-statuses/index.vue?macro=true";
import { default as stock_45statuses5UgOduINnjMeta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20250124223046/pages/stock-statuses.vue?macro=true";
import { default as index7KaHqTRvxBMeta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20250124223046/pages/stock/index.vue?macro=true";
import { default as createO4SBmfjU22Meta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20250124223046/pages/stock/statuses/create.vue?macro=true";
import { default as create7UrAJlaBWqMeta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20250124223046/pages/stock/transfers/create.vue?macro=true";
import { default as indexyIK5ZdJhrGMeta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20250124223046/pages/tables/[tableId]/orders/[orderId]/index.vue?macro=true";
import { default as splitGXpon1bgunMeta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20250124223046/pages/tables/[tableId]/orders/[orderId]/split.vue?macro=true";
import { default as _91orderId_9370OIPrQuYyMeta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20250124223046/pages/tables/[tableId]/orders/[orderId].vue?macro=true";
import { default as createlNVbxTf7I8Meta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20250124223046/pages/tables/[tableId]/orders/create.vue?macro=true";
import { default as _91tableId_93SwvR9KXyHTMeta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20250124223046/pages/tables/[tableId].vue?macro=true";
import { default as index7PX7vKx3yoMeta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20250124223046/pages/tables/index.vue?macro=true";
import { default as indexI81luMHnuzMeta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20250124223046/pages/takeaway-orders/[id]/index.vue?macro=true";
import { default as split1afSjH7CD3Meta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20250124223046/pages/takeaway-orders/[id]/split.vue?macro=true";
import { default as _91id_931QRVYXhH2oMeta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20250124223046/pages/takeaway-orders/[id].vue?macro=true";
import { default as createYVfXIVXAJAMeta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20250124223046/pages/takeaway-orders/create.vue?macro=true";
import { default as indexGQ6eXMSeIqMeta } from "/var/www/sites/terminal.ultrafedt.dk/releases/20250124223046/pages/takeaway-orders/index.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20250124223046/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: loginDrM4ltbgJvMeta || {},
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20250124223046/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "orders-id",
    path: "/orders/:id()",
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20250124223046/pages/orders/[id].vue").then(m => m.default || m)
  },
  {
    name: "orders-create",
    path: "/orders/create",
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20250124223046/pages/orders/create.vue").then(m => m.default || m)
  },
  {
    name: "orders",
    path: "/orders",
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20250124223046/pages/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "print-jobs",
    path: "/print-jobs",
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20250124223046/pages/print-jobs.vue").then(m => m.default || m)
  },
  {
    name: "reservations",
    path: "/reservations",
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20250124223046/pages/reservations/index.vue").then(m => m.default || m)
  },
  {
    name: "reservations-lanes",
    path: "/reservations/lanes",
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20250124223046/pages/reservations/lanes.vue").then(m => m.default || m)
  },
  {
    name: "settlement",
    path: "/settlement",
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20250124223046/pages/settlement.vue").then(m => m.default || m)
  },
  {
    name: "setup-merchantId",
    path: "/setup/:merchantId()",
    meta: _91merchantId_9378YZbPHGq4Meta || {},
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20250124223046/pages/setup/[merchantId].vue").then(m => m.default || m)
  },
  {
    name: "setup",
    path: "/setup",
    meta: indexaZuXKilMcpMeta || {},
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20250124223046/pages/setup/index.vue").then(m => m.default || m)
  },
  {
    name: stock_45statuses5UgOduINnjMeta?.name,
    path: "/stock-statuses",
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20250124223046/pages/stock-statuses.vue").then(m => m.default || m),
    children: [
  {
    name: "stock-statuses-id-edit",
    path: ":id()/edit",
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20250124223046/pages/stock-statuses/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: "stock-statuses-id",
    path: ":id()",
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20250124223046/pages/stock-statuses/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "stock-statuses-create",
    path: "create",
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20250124223046/pages/stock-statuses/create.vue").then(m => m.default || m)
  },
  {
    name: "stock-statuses",
    path: "",
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20250124223046/pages/stock-statuses/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "stock",
    path: "/stock",
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20250124223046/pages/stock/index.vue").then(m => m.default || m)
  },
  {
    name: "stock-statuses-create",
    path: "/stock/statuses/create",
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20250124223046/pages/stock/statuses/create.vue").then(m => m.default || m)
  },
  {
    name: "stock-transfers-create",
    path: "/stock/transfers/create",
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20250124223046/pages/stock/transfers/create.vue").then(m => m.default || m)
  },
  {
    name: "tables-tableId",
    path: "/tables/:tableId()",
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20250124223046/pages/tables/[tableId].vue").then(m => m.default || m),
    children: [
  {
    name: _91orderId_9370OIPrQuYyMeta?.name,
    path: "orders/:orderId()",
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20250124223046/pages/tables/[tableId]/orders/[orderId].vue").then(m => m.default || m),
    children: [
  {
    name: "tables-tableId-orders-orderId",
    path: "",
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20250124223046/pages/tables/[tableId]/orders/[orderId]/index.vue").then(m => m.default || m)
  },
  {
    name: "tables-tableId-orders-orderId-split",
    path: "split",
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20250124223046/pages/tables/[tableId]/orders/[orderId]/split.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "tables-tableId-orders-create",
    path: "orders/create",
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20250124223046/pages/tables/[tableId]/orders/create.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "tables",
    path: "/tables",
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20250124223046/pages/tables/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_931QRVYXhH2oMeta?.name,
    path: "/takeaway-orders/:id()",
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20250124223046/pages/takeaway-orders/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "takeaway-orders-id",
    path: "",
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20250124223046/pages/takeaway-orders/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "takeaway-orders-id-split",
    path: "split",
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20250124223046/pages/takeaway-orders/[id]/split.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "takeaway-orders-create",
    path: "/takeaway-orders/create",
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20250124223046/pages/takeaway-orders/create.vue").then(m => m.default || m)
  },
  {
    name: "takeaway-orders",
    path: "/takeaway-orders",
    component: () => import("/var/www/sites/terminal.ultrafedt.dk/releases/20250124223046/pages/takeaway-orders/index.vue").then(m => m.default || m)
  }
]